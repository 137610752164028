import { init, captureException, captureMessage, setUser } from "@sentry/nextjs";

import { Integrations } from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/nextjs";

const getSentryConfig = () => {
  const SENTRY_DSN = process.env.NEXT_SENTRY_DSN;
  const DEBUG = process.env.NEXT_SENTRY_DEBUG === "yes" || false;
  const ENVIRONMENT = process.env.NEXT_SENTRY_ENVIRONMENT;

  const dsn = SENTRY_DSN;
  let denyUrls = ["0.0.0.0", "localhost", "127.0.0.1", "::1"];
  //   if (DEBUG) {
  //     denyUrls = [];
  //   }
  let tracesSampleRate = ENVIRONMENT === "development" ? 0 : 1;
  return {
    release: process.env.NEXT_PROJECT_VERSION,
    tracesSampleRate,
    dsn,
    environment: ENVIRONMENT,
    denyUrls,
    debug: DEBUG,
  };
};

const getSentryIntegrations = (type) => {
  const integrations = [];
  if (type === "client") {
    integrations.push(
      new BrowserTracing({
        tracingOrigins: ["::1"],
      })
    );
    integrations.push(
      new Integrations.Breadcrumbs({
        console: false,
      })
    );
  }
  return integrations;
};

export const Tracing = {
  init: (type) => {
    try {
      const config = getSentryConfig();
      const integrations = getSentryIntegrations(type);
      console.debug("Configuring tracing", config);
      if (config.environment === "development") {
        console.debug("Tracing disabled for development");
        return;
      }
      init({
        ...config,
        integrations: integrations,
      });
    } catch (error) {
      console.error("Tracing init", error);
    }
  },
  capture: (exception) => {
    try {
      captureException(exception);
    } catch (error) {
      console.error("Tracing.capture error", error.message, exception);
    }
  },
  captureMessage: (message, context) => {
    try {
      captureMessage(message, context);
    } catch (error) {
      console.error("Tracing.captureMessage error", error.message, message);
    }
  },
  setUser: (user) => {
    try {
      setUser(user);
    } catch (error) {
      console.error("Tracing.setUser error", error.message, user);
    }
  },
};
