export const pageview = (url) => {
  if (window.gtag) {
    window.gtag("config", process.env.NEXT_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

export const event = ({ action, params }) => {
  if (window.gtag) {
    window.gtag("event", action, params);
  } else {
    console.log("window.gtag is not defined");
  }
};
