import axios from "axios";
import { stringify as stringifyQuery } from "query-string";

const config = {
  userApi: {
    url: `${process.env.NEXT_PUBLIC_API_URL_API_URL}`,
    username: "",
    password: "",
  },
};

class AppService {
  constructor(token) {
    const { username, password } = config.userApi;
    let { url } = config.userApi;

    if (!!username && !!password) {
      url = url.replace(/^(https?:\/\/)/i, `$1${username}:${password}@`);
    }

    this.url = url;
    this.token = token || null;
    this.user = null;
  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;

    // console.log(7777, 'token is setted', token)
  }

  clearToken() {
    this.token = null;
  }

  setUser(data) {
    this.user = data;

    // console.log(11111, 'User is setted')
  }

  getUser() {
    // console.log(11111, 'User is getted', this.user)

    return this.user;
  }

  getDefaultHeaders() {
    const defaultAuthenticationHeaders = {
      "Content-Type": "application/json",
    };

    return this.token
      ? {
          ...defaultAuthenticationHeaders,
          authorization: `Bearer ${this.token}`,
        }
      : {};
  }

  async get(path, query = {}, timeout) {
    console.log("get", path, query);
    const { data } = await axios.get(`${this.url}/${path}`, {
      headers: this.getDefaultHeaders(),
      params: query,
      paramsSerializer: stringifyQuery,
      timeout,
    });

    return data;
  }

  async post(path, body = {}, timeout) {
    const { data } = await axios.post(`${this.url}/${path}`, {
      headers: this.getDefaultHeaders(),
      timeout,
      body,
    });

    return data;
  }

  async delete(path, timeout) {
    const { data } = await axios.delete(`${this.url}/${path}`, {
      headers: this.getDefaultHeaders(),
      timeout,
    });

    return data;
  }
}

export default AppService;
