import { useEffect, useState } from "react";

const useUTMParams = () => {
  const [utmParams, setUTMParams] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // using URLSearchParams because router.query sucks so much, it is null on first render and we need to track it, so messy
      const urlSearchParams = new URLSearchParams(window.location.search);
      const path = window.location.pathname.split("/").slice(1).join("/");
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.utm_source || params.utm_medium || params.utm_campaign || params.utm_term || params.utm_content) {
        const utmParamsObj = {
          source: params.utm_source || null,
          medium: params.utm_medium || null,
          campaign: params.utm_campaign || null,
          term: params.utm_term || null,
          content: params.utm_content || null,
          path: path || null,
        };
        setUTMParams(utmParamsObj);
      }
    }
  }, []);

  useEffect(() => {
    if (utmParams) {
      localStorage.setItem("utmParams", JSON.stringify(utmParams));
    }
  }, [utmParams]);

  return utmParams;
};

const getUTMParams = () => {
  const utmParams = localStorage.getItem("utmParams");
  return utmParams ? JSON.parse(utmParams) : null;
};

export { getUTMParams };

export default useUTMParams;
