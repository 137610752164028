/* eslint-disable react/react-in-jsx-scope */

import { createContext, useContext, useState } from "react";
const initialState = {
  leagueType: "college_mens",
  setLeagueType: () => {},
  leagueModalOpen: true,
  isSignUpCoach: true,
  params: null,
  thresholdValue: null,
  summaryData: null,
  overallSummaryData: null,
  showLiveLinkHeader: true,
  user: null,
  userDetail: null,
};
let withStorage = {};
try {
  if (typeof localStorage !== "undefined") {
    withStorage = JSON.parse(localStorage.getItem("store")) || {};
  }
} catch (e) {
  console.error("Error parsing local storage", e);
}

export const AppContext = createContext({ ...initialState, ...withStorage });

export const AppContextWrapper = ({ children, state }) => {
  const [leagueType, setLeagueType] = useState("college_mens");
  return <AppContext.Provider value={{ ...state, leagueType, setLeagueType }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
