import axios from "axios";

export const authGetRequest = async (url, authToken, shouldRetry = true) => {
  let config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let res;
  try {
    res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL_API_URL}${url}`, {
      headers: { ...config },
      withCredentials: false,
    });
  } catch (err) {
    if (err.message.includes("401") && shouldRetry) {
      try {
        res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: authToken,
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.access_token !== undefined) {
              setCookie("token_access", response.access_token, 786);
              location.reload();
            }
          });
        return authGetRequest(url, authToken, false);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    console.error("Error Loading Defensive Data:", err);
    return null;
  }
  return res;
};

export const authPostRequest = async (url, authToken, params) => {
  let config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_API_URL}${url}`, params, {
    headers: { ...config },
    withCredentials: false,
  });

  return res;
};

export const postRequest = async (url, params) => {
  let config = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_API_URL}${url}`, params, {
    headers: { ...config },
    withCredentials: false,
  });

  return res;
};

export const getRequest = async (url) => {
  let config = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL_API_URL}${url}`, {
    headers: { ...config },
    withCredentials: false,
  });

  return res;
};

export const authGetReqv2 = async (url, authToken) => {
  let config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  let res;
  try {
    res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL_API_URL}${url}`, {
      headers: { ...config },
      withCredentials: false,
    });
  } catch (err) {
    console.error("Error : ", err);
    return err.response.data;
  }
  return res.data;
};

export const authRefreshToken = async () => {
  try {
    const authToken = document.cookie.split("token_access=").pop().split(";").shift();
    let res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: authToken,
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      });
    return res;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

function setCookie(cookieName, cookieValue, expiryDays) {
  const date = new Date();
  date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}
