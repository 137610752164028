import nookies, { destroyCookie } from "nookies";

import AppService from "../services/AppService";

export const getAppService = async (ctx) => {
  let appService = ctx.req?.appService;
  if (appService) return appService;
  const cookies = nookies.get(ctx);
  const { token_access } = cookies;

  if (token_access && token_access != "undefined") {
    appService = new AppService(token_access);
    appService.setUser(cookies.name);

    const payload = token_access?.split(".")[1];

    const { exp } = JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    const expTime = new Date(+exp * 1000);
    const now = new Date();

    try {
      if (expTime < now) {
        fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token_access}`,
          },
          body: token_access,
        })
          .then((r) => r.json())
          .then((data) => {
            if (data?.access_token) {
              appService.setToken(data?.access_token);
              nookies.set(null, "token_access", data?.access_token, {
                path: "/",
                maxAge: 31536000,
              });
            } else {
              destroyCookie({}, "token_access");
              destroyCookie({}, "name");
            }
          })
          .catch((e) => {
            destroyCookie({}, "token_access");
            destroyCookie({}, "name");
            console.error(e);
          });
      }
    } catch (error) {
      appService = new AppService();
      if (error?.response?.status !== 401) throw error;
    }
  } else {
    appService = new AppService();
  }

  if (ctx.req) {
    ctx.req.appService = appService;
  }

  return appService;
};

export function appServerSideProps(getServerSideProps) {
  return async (context) => {
    const appService = await getAppService(context);
    const user = appService.getUser();

    if (!getServerSideProps) return { props: {} };

    const appContext = context;

    appContext.appService = appService;
    appContext.user = user;

    try {
      return await getServerSideProps(appContext);
    } catch (error) {
      return { notFound: true };
    }
  };
}
